import Layout from './Layout'
import React from 'react'
import { BlogSideBlock } from './templates/BlogList'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import { Link } from 'gatsby'
import { FoodRecipe } from './templates/FoodRecipe'
import {
  FloatingElement,
  FloatingFlex,
} from './templates/articles/FloatingElement'
import { RangeSlider } from './templates/inputs/RangeSlider'
import { ArticleActions } from './templates/articles/ArticleActions'
import { CodeBlockTitle } from '../components/templates/articles/CodeBlockTitle'
import useMobileDevice from '../hooks/useMobileDevice'
import { MobileShare } from './MobileShare'

const Blogpost = (props) => {
  const { location } = props
  const {
    title,
    title_numbering,
    last_updated,
    date,
    smallestImage,
    smallImage,
    mediumImage,
    meta_description,
    author,
    keywords,
  } = props.data.mdx.frontmatter
  const { body, slug } = props.data.mdx
  const [isMobileOrTablet] = useMobileDevice()
  const coverImageSources = [
    smallestImage && smallestImage.childImageSharp.fluid,
    {
      ...(mediumImage && mediumImage.childImageSharp.fluid),
      media: `(min-width: 992px)`,
    },
    {
      ...(smallImage && smallImage.childImageSharp.fluid),
      media: `(min-width: 479px)`,
    },
  ]
  const setFromLink = () => {
    if (location.state && location.state.fromBlogList === 'food') {
      return '/foodblog'
    } else {
      return '/blog'
    }
  }
  const shortcodes = {
    FoodRecipe,
    FloatingElement,
    FloatingFlex,
    RangeSlider,
    CodeBlockTitle,
  }
  const getArticleClassName = () => {
    let classString = 'article '
    if (slug.includes('food')) {
      classString += 'food-article'
      return classString
    }
    return classString
  }
  return (
    <MDXProvider components={shortcodes}>
      <Layout
        metaTitle={`Blogpost | ${title}`}
        metaDescription={meta_description && meta_description}
        smallHeader
      >
        <div className="container blog-list-container">
          <BlogSideBlock slug={slug} author={author} title={title} />
          <div className={getArticleClassName()}>
            <div className="back-button">
              <Link to={setFromLink()}>&larr; Takaisin</Link>
            </div>
            <h1>{title}</h1>
            <div className="information-section">
              <div className="blog-updated">
                <p>Published: {date}</p>
                <p>Last update: {last_updated}</p>
              </div>
              <p>{props.data.mdx.fields.readingTime.text}</p>
            </div>
            {smallImage && mediumImage && (
              <Img className="post-header-img" fluid={coverImageSources} />
            )}
            <div
              className={`article-content ${
                title_numbering && 'title-numbering'
              }`}
            >
              <MDXRenderer>{body}</MDXRenderer>
              <ArticleActions slug={slug} />
            </div>
            {keywords && (
              <div className="article-keywords">
                {keywords.map((item) => {
                  return (
                    <div className="keyword-item" key={`keyword-${item}`}>
                      {item}
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
        {isMobileOrTablet && (
          <MobileShare postTitle={title} siteTitle={'iampedro.dev'} />
        )}
      </Layout>
    </MDXProvider>
  )
}

export const query = graphql`
  query PostQuery($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        title_numbering
        last_updated(formatString: "MMMM Do, YYYY")
        date(formatString: "MMMM Do, YYYY")
        author
        keywords
        meta_description
        smallestImage: image {
          childImageSharp {
            fluid(
              maxWidth: 250
              maxHeight: 125
              cropFocus: CENTER
              quality: 50
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        smallImage: image {
          childImageSharp {
            fluid(
              maxWidth: 400
              maxHeight: 200
              cropFocus: CENTER
              quality: 50
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mediumImage: image {
          childImageSharp {
            fluid(maxWidth: 800, maxHeight: 400, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      fields {
        readingTime {
          text
        }
      }
      body
      slug
    }
  }
`

export default Blogpost
