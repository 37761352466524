import React from 'react'

const FloatingElement = (props) => {
  const { children, caption, left, right = 'true', width = '100%' } = props
  const getClassName = () => {
    let classString = 'floating-container'
    if (width === '100%') {
      return (classString += ' float-none')
    }
    if (left) {
      return (classString += ' left')
    }
    if (right) {
      return (classString += ' right')
    }
  }
  return (
    <div
      className={getClassName()}
      style={{ ...(width && { width: `${width}` }) }}
    >
      <div className="floating-item">{children}</div>
      {caption && <div className="floating-caption">{caption}</div>}
    </div>
  )
}

const FloatingFlex = (props) => {
  const { children } = props
  return <div className="floating-flex">{children}</div>
}

export { FloatingElement, FloatingFlex }
