import React, { useState, useEffect } from 'react'
const RangeSlider = (props) => {
  const {
    classes,
    label,
    onChange,
    value,
    id,
    children,
    unit,
    ...sliderProps
  } = props
  const [sliderVal, setSliderVal] = useState(0)
  const [mouseState, setMouseState] = useState(null)
  useEffect(() => {
    setSliderVal(value)
  }, [value])
  const changeCallback = (e) => {
    setSliderVal(e.target.value)
  }
  useEffect(() => {
    if (mouseState === 'up') {
      onChange(sliderVal)
    }
  }, [mouseState])
  return (
    <>
      <div className="range-slider">
        {/*<p>{label}</p>*/}
        <label htmlFor={id}>
          <h3>
            {label}: {sliderVal}
            {unit}
          </h3>
        </label>
        <input
          type="range"
          value={sliderVal}
          {...sliderProps}
          className={`slider ${classes}`}
          id={id}
          onChange={changeCallback}
          onMouseDown={() => setMouseState('down')}
          onMouseUp={() => setMouseState('up')}
        />
      </div>
      <style jsx="true" global="true">
        {`
          .svg-check-slider {
            stroke-dashoffset: ${sliderVal};
          }
          #predefined-height {
            height: ${sliderVal}rem!important;
          }
        `}
      </style>
    </>
  )
}
export { RangeSlider }
