import React from 'react'
import { toFraction } from '../../../utils/numbers'

const Fraction = (props) => {
  const { value } = props
  const fraction = toFraction(value)
  if (Array.isArray(fraction)) {
    return (
      <span className="fraction-container">
        {fraction[0] !== 0 && <span>{fraction[0]}</span>}
        <sup>{fraction[1]}</sup>
        &frasl;
        <sub>{fraction[2]}</sub>
      </span>
    )
  } else {
    return value
  }
}

export { Fraction }
