import React, { useEffect, useState } from 'react'
import { CustomCheckbox } from '../templates/inputs/CustomCheckbox'
import Img from 'gatsby-image'
import PrinterIcon from '../../../static/svg/printer.svg'
import ShareIcon from '../../../static/svg/share-icon.svg'
import { graphql, useStaticQuery } from 'gatsby'
import { RecipeRowLabel } from './articles/foodblog/RecipeRowLabel'
import { CountToggler } from './inputs/CountToggler'

const FoodRecipe = (props) => {
  const { recipe, title_color } = props
  const data = useStaticQuery(
    graphql`
      query {
        allMdx(filter: { fileAbsolutePath: { regex: "/content/recipes/" } }) {
          edges {
            node {
              id
              frontmatter {
                description
                steps
                cooking_time
                prep_time
                total_time
                ingredients {
                  ingredient
                  amount
                  unit
                  alias_amount
                  alias_unit
                  title
                }
                title
                serves
                image {
                  childImageSharp {
                    fluid(maxWidth: 200, maxHeight: 200, cropFocus: CENTER) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              fileAbsolutePath
            }
          }
        }
      }
    `
  )
  const { edges } = data.allMdx
  const getRecipe = edges.find(({ node }) =>
    node.fileAbsolutePath.includes(`${recipe}`)
  )
  const {
    ingredients,
    serves,
    steps,
    title,
    description,
    cooking_time,
    prep_time,
    total_time,
  } = getRecipe.node.frontmatter
  const recipeImage =
    getRecipe.node.frontmatter.image &&
    getRecipe.node.frontmatter.image.childImageSharp.fluid
  const [serveInput, setServeInput] = useState(serves)
  const [stepChecked, setStepChecked] = useState([])
  const addCount = () => {
    if (isNaN(serveInput)) {
      setServeInput(1)
    } else {
      setServeInput(serveInput + 1)
    }
  }
  const removeCount = () => {
    if (isNaN(serveInput)) {
      setServeInput(1)
    } else if (serveInput > 1) {
      setServeInput(serveInput - 1)
    }
  }
  const handleServeChange = (event) => {
    setServeInput(Number.parseInt(event.target.value))
  }

  const convertUnit = (testAmount, testUnit) => {
    let divider = 10
    if (testUnit === 'ml') {
      for (let i = 0; i < 5; i++) {
        if (testAmount % divider === 0) {
          divider = divider * 10
        } else {
          const newValue = testAmount / divider
          const newUnit = i
          return newValue
        }
      }
    }
  }

  useEffect(() => {
    const list = new Array(steps.length).fill().map((item, id) => false)
    setStepChecked(list)
  }, [steps])

  const handleStepChecked = (id) => {
    const stepCheckedCopy = [...stepChecked]
    stepCheckedCopy[id] = !stepCheckedCopy[id]
    setStepChecked(stepCheckedCopy)
  }

  return (
    <div className="food-recipe-container">
      <div className="share-options">
        <div>
          <ShareIcon />
        </div>
        <div>
          <PrinterIcon />
        </div>
      </div>
      <div className="image-container">
        {recipeImage && <Img fluid={recipeImage} />}
      </div>
      <div className="cooking-times">
        <div>
          <div>Prep time</div>
          <div>{prep_time || 0} mins</div>
        </div>
        <div>
          <div>Cooking time</div>
          <div>{cooking_time || 0} mins</div>
        </div>
        <div>
          <div>Total</div>
          <div>{total_time || 0} mins</div>
        </div>
      </div>
      <h2
        className="recipe-title"
        style={{ ...(title_color && { color: `${title_color}` }) }}
      >
        {title}
      </h2>
      <p>{description}</p>
      <h2 className="ingredients-title">
        Ingredients{' '}
        <CountToggler
          min={1}
          onChange={handleServeChange}
          addCount={() => {
            addCount()
          }}
          removeCount={() => {
            removeCount()
          }}
          count={serveInput}
        />{' '}
        serves
      </h2>
      <ul className="ingredients-list">
        {ingredients.map(
          (
            { ingredient, amount, unit, alias_amount, alias_unit, title },
            id
          ) => {
            const calculateAmount =
              serveInput && amount ? (amount / serves) * serveInput : amount
            const calculateAliasAmount =
              serveInput && alias_amount
                ? (alias_amount / serves) * serveInput
                : alias_amount
            return (
              <React.Fragment key={`${title}-ingredient-${id}`}>
                {title && <h3 className="ingredient-sub-header">{title}</h3>}
                <li>
                  <CustomCheckbox
                    id={`${ingredient}-${id}`}
                    labelFi={
                      <RecipeRowLabel
                        amount={calculateAmount}
                        unit={unit}
                        alias_amount={calculateAliasAmount}
                        alias_unit={alias_unit}
                        ingredient={ingredient}
                      />
                    }
                    lineThrough
                  />
                </li>
              </React.Fragment>
            )
          }
        )}
      </ul>
      <h2>Steps</h2>
      {steps.map((stepDescription, id) => {
        return (
          <React.Fragment key={`${title}-step-${id}`}>
            <h3 className="recipe-step-item">
              STEP {id + 1}{' '}
              <div
                className="mark-step-done"
                onClick={() => handleStepChecked(id)}
              >
                {stepChecked[id] ? 'Undo' : 'Mark as Done'}
              </div>
            </h3>
            <p
              className={`step-description ${
                stepChecked[id] && 'step-description__checked'
              }`}
            >
              {stepDescription}
            </p>
          </React.Fragment>
        )
      })}
    </div>
  )
}

export { FoodRecipe }
