const gcd = (a, b) => {
  return b ? gcd(b, a % b) : a
}
const toFraction = (decimal) => {
  const value = Math.floor(decimal)
  if (decimal && !Number.isInteger(decimal)) {
    const numerator = (decimal - value) * 100

    const reducer = gcd(numerator, 100)

    return [value, numerator / reducer, 100 / reducer]
  }
  return decimal
}

export { toFraction }
