import React from 'react'

const CodeBlockTitle = (props) => {
  const { children } = props
  return (
    <div className="code-block-title">
      <div className="cb-title-content">{children || 'insert file path'}</div>
    </div>
  )
}

export { CodeBlockTitle }
