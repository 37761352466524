import React from 'react'
import { listize } from '../../../../utils/list'
import { Fraction } from '../../inputs/Fraction'

const RecipeRowLabel = (props) => {
  const { amount, unit, alias_amount, alias_unit, ingredient } = props
  if (alias_amount) {
    return (
      <>
        <Fraction value={amount} />
        {` `}
        {listize(unit)}
        {` `}
        <span className="alias-amount">
          ({alias_amount} {alias_unit}){` `}
        </span>
        {listize(ingredient)}
      </>
    )
  } else {
    return (
      <>
        <Fraction value={amount} />
        {` `}
        {listize(unit, ingredient)}
      </>
    )
  }
}

export { RecipeRowLabel }
