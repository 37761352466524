import React from 'react'
import PropTypes from 'prop-types'
import ShareIcon from '../../static/svg/share-icon.svg'

const MobileShare = ({ postTitle, siteTitle }) => {
  const handleOnClick = () => {
    if (navigator.share) {
      navigator
        .share({
          title: `${postTitle} | ${siteTitle}`,
          text: `Check out ${postTitle} on ${siteTitle}`,
          url: document.location.href,
        })
        .then(() => {
          console.log('Successfully shared')
        })
        .catch((error) => {
          console.error('Something went wrong sharing the blog', error)
        })
    }
  }

  return (
    <div className="mobile-share-wrapper" onClick={handleOnClick}>
      <ShareIcon />
    </div>
  )
}

MobileShare.propTypes = {
  postTitle: PropTypes.string.isRequired,
  siteTitle: PropTypes.string.isRequired,
}

export { MobileShare }
